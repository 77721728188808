import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  HStack,
  Input,
  PinInput,
  PinInputField,
  Spacer,
  Stack,
  StackProps,
  Text,
  useBreakpointValue,
  useToast,
} from "@chakra-ui/react";
import { ReactElement, useState } from "react";
import { Logo } from "../../layout/Logo";
import { Auth } from "aws-amplify";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";

type RegisterPageState = {
  signedUp: boolean;
  confirmed: boolean;
  submitingSignUp: boolean;
  submittingConfirmation: boolean;
  username: string;
  password: string;
  name: string;
  confirmationCode: string;
};

const RegisterPage = (props: StackProps) => {
  const [state, setState] = useState<RegisterPageState>({
    signedUp: false,
    confirmed: false,
    submitingSignUp: false,
    submittingConfirmation: false,
    name: "",
    username: "",
    password: "",
    confirmationCode: "",
  });

  const isMobile = useBreakpointValue({ base: true, md: false });
  const toast = useToast();

  const validationSchema = Yup.object().shape({
    username: Yup.string()
      .email("E-mail inválido!")
      .required("E-mail é obrigatório!"),
    name: Yup.string().min(2, "Informe seu nome!"),
    password: Yup.string()
      .required("Informe sua senha!")
      .min(6, "Sua senha precisa ter pelo menos 6 caracteres")
      .matches(
        /(.*[a-z].*)/,
        "Sua senha precisa ter pelo menos uma letra minúscula"
      )
      .matches(
        /(.*[A-Z].*)/,
        "Sua senha precisa ter pelo menos uma letra maiúscula"
      )
      .matches(/(.*\d.*)/, "Sua senha precisa ter pelo menos um número")
      .matches(
        /(.*\W.*)/,
        "Sua senha precisa ter pelo menos um caractere especial"
      ),
  });

  const onConfirmCode = async (values: any) => {
    const { confirmed, signedUp } = state;

    // Enviando código de confirmação da conta.
    if (!confirmed && signedUp) {
      setState({ ...state, submittingConfirmation: true });

      try {
        await Auth.confirmSignUp(values.username, values.confirmationCode);
        setState({ ...state, submittingConfirmation: false, confirmed: true });
      } catch (err) {
        console.error(err);
        setState({ ...state, submittingConfirmation: false });
      }

      return;
    }
  };

  /**
   * Transforma a mensagem em inglês para uma mensagem em português.
   * Para tal, se baseia no Type retornado na chamada.
   *
   * @param type Tipo que vem da Amazon.
   */
  const getErrorMessage = (type: string): string => {
    let result = "";

    switch (type) {
      case "UsernameExistsException":
        result = "Este usuário já se encontra cadastrado.";
        break;
      case "InvalidPasswordException":
        result =
          "Sua senha deve conter 6 caracteres, uma letra minúscula, um número e um caracter especial.";
        break;
    }

    return result;
  };

  const onRegister = async (values: any) => {
    setState({
      ...state,
      username: values.username,
      password: values.password,
      confirmationCode: values.confirmationCode,
    });

    const { confirmed, signedUp } = state;

    // Realizando o registro da conta.
    if (!confirmed && !signedUp) {
      setState({ ...state, submitingSignUp: true });

      try {
        await Auth.signUp({
          username: values.username,
          password: values.password,
          attributes: { name: values.name },
        });
        setState({ ...state, signedUp: true, submitingSignUp: false });
      } catch (err: any) {
        setState({ ...state, submitingSignUp: false });

        toast({
          title: "Ocorreu um erro!",
          description: getErrorMessage(err.name),
          status: "error",
          isClosable: true,
        });
      }
    }
  };

  /**
   * Define qual formulário deve ser exibido.
   *
   * Existem dois formulários: um para receber o email/senha e outro
   * para receber o código de confirmação.
   *
   * Também exibe uma mensagem de que a conta foi criada com sucesso!
   *
   * @returns Formulário que deve ser exibido.
   */
  const createForm = (): ReactElement => {
    let result = (
      <Stack spacing="6">
        <Formik
          validationSchema={validationSchema}
          onSubmit={onRegister}
          initialValues={{
            username: "",
            password: "",
            name: "",
            confirmationCode: "",
          }}
        >
          {({ handleSubmit, errors, touched }) => (
            <form onSubmit={handleSubmit}>
              <Stack spacing="5">
                <FormControl isInvalid={!!errors.name && touched.name}>
                  <FormLabel htmlFor="name">Nome</FormLabel>
                  <Field
                    as={Input}
                    id="name"
                    name="name"
                    placeholder="Seu nome"
                    type="text"
                  />
                  <FormErrorMessage>{errors.name}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={!!errors.username && touched.username}>
                  <FormLabel htmlFor="username">E-mail</FormLabel>
                  <Field
                    as={Input}
                    id="username"
                    name="username"
                    placeholder="Seu e-mail"
                    type="email"
                  />
                  <FormErrorMessage>{errors.username}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={!!errors.password && touched.password}>
                  <FormLabel htmlFor="password">Senha</FormLabel>
                  <Field
                    as={Input}
                    id="password"
                    name="password"
                    placeholder="Uma senha forte"
                    type="password"
                  />
                  <FormErrorMessage>{errors.password}</FormErrorMessage>
                </FormControl>
              </Stack>
              <Spacer height={5}></Spacer>
              <Stack spacing="4">
                <Button
                  disabled={state.submitingSignUp}
                  variant="primary"
                  type="submit"
                >
                  Registrar
                </Button>
              </Stack>
            </form>
          )}
        </Formik>
      </Stack>
    );

    if (state.confirmed) {
      result = (
        <div>
          Sua conta foi criada com sucesso! Agora você já pode fazer seu login!
        </div>
      );
    } else if (state.signedUp) {
      result = (
        <Stack spacing="6">
          <Formik
            initialValues={{ confirmationCode: "000000" }}
            onSubmit={onConfirmCode}
          >
            {({ handleSubmit, errors, touched }) => (
              <form onSubmit={handleSubmit}>
                <Stack spacing="5">
                  <FormControl
                    isInvalid={
                      !!errors.confirmationCode && touched.confirmationCode
                    }
                  >
                    <FormLabel htmlFor="confirmationCode">
                      Código de Confirmação
                    </FormLabel>
                    <Field
                      as={Input}
                      id="confirmationCode"
                      name="confirmationCode"
                      placeholder="Código de Confirmação"
                      type="text"
                    />
                    <FormErrorMessage>
                      {errors.confirmationCode}
                    </FormErrorMessage>
                  </FormControl>
                </Stack>
                <Spacer height={5} />
                <Stack spacing="4">
                  <Button
                    disabled={
                      state.submitingSignUp || state.submittingConfirmation
                    }
                    variant="primary"
                    type="submit"
                  >
                    Confirmar
                  </Button>
                </Stack>
              </form>
            )}
          </Formik>
        </Stack>
      );
    }

    return result;
  };

  return (
    <Stack spacing="8" {...props} w="full">
      <Stack spacing="6">
        <Stack spacing={{ base: "2", md: "3" }} textAlign="center">
          <Heading size={useBreakpointValue({ base: "xs", md: "sm" })}>
            Criar uma nova conta
          </Heading>
          <HStack spacing="1" justify="center">
            <Text color="muted">Já tem uma conta? Então </Text>
            <Link to="/login">faça login</Link>
          </HStack>
        </Stack>
      </Stack>
      {createForm()}
    </Stack>
  );
};

export default RegisterPage;
