import {
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Input,
  Stack,
  StackProps,
  Text,
  useBreakpointValue,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import { Auth } from "aws-amplify";
import { Link, useLocation } from "react-router-dom";

type LoginPageState = {
  signedIn: boolean;
  isSigningIn: boolean;
  username: string;
  password: string;
  tokenId: string;
  refreshToken: string;
};

const LoginPage = (props: StackProps) => {
  const toast = useToast();
  const [state, setState] = useState<LoginPageState>({
    signedIn: false,
    isSigningIn: false,
    username: "",
    password: "",
    tokenId: "",
    refreshToken: "",
  });

  const isMobile = useBreakpointValue({ base: true, md: false });

  /**
   * Checa por uma mensagem passada na url para o usuário
   */
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const message = queryParams.get("message");

  if (message) {
    if (!toast.isActive("login-message")) {
      toast({
        id: "login-message",
        title: "Atenção",
        description: message,
        status: "warning",
        duration: 9000,
        isClosable: true,
      });
    }
  }

  /**
   * Trata a mudança de valor dos atributos do formulário, atribuindo
   * automaticamente ao estado da página.
   *
   * @param e Evento.
   */
  const onInputChange = (e: any) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  /**
   * Realiza o login, chamando o Amplify.
   *
   */
  const onLogin = async () => {
    const { signedIn, username, password } = state;

    if (!signedIn) {
      setState({ ...state, isSigningIn: true });

      try {
        await Auth.signIn({ username: username, password: password });
      } catch (err) {
        setState({ ...state, isSigningIn: false });

        toast({
          title: "Ocorreu um erro!",
          description: "Verifique seu usuário e/ou senha!",
          status: "error",
          isClosable: true,
        });

        return;
      }

      try {
        const userSession = await Auth.currentSession();

        setState({
          ...state,
          signedIn: true,
          isSigningIn: false,
          tokenId: userSession.getIdToken().getJwtToken(),
          refreshToken: userSession.getRefreshToken().getToken(),
        });

        toast({
          title: "Tudo certo!",
          description: "Estamos te redirecionando...",
          status: "success",
          isClosable: true,
        });

        let clientUrl = process.env.REACT_APP_GVSL_REDIRECT_URL;

        const queryString = new URLSearchParams({
          token: userSession.getIdToken().getJwtToken(),
        });

        window.location.replace(`${clientUrl}?${queryString}`);
      } catch (err) {
        setState({ ...state, isSigningIn: false });

        toast({
          title: "Oops...",
          description: "Aconteceu um erro. Tenta novamente, por favor?",
          status: "error",
          isClosable: true,
        });

        return;
      }
    }
  };

  return (
    <Stack spacing="8" width="full" {...props}>
      <Stack spacing="6">
        <Stack spacing={{ base: "2", md: "3" }} textAlign="center">
          <Heading size={useBreakpointValue({ base: "xs", md: "sm" })}>
            Entre em sua conta
          </Heading>
          <HStack spacing="1" justify="center">
            <Text color="muted">Ainda não tem uma?</Text>
            <Text fontWeight="bold">
              <Link to="/register">Criar agora.</Link>
            </Text>
          </HStack>
        </Stack>
      </Stack>
      <Stack spacing="6">
        <Stack spacing="5">
          <FormControl>
            <FormLabel htmlFor="email">E-mail</FormLabel>
            <Input
              id="email"
              name="username"
              placeholder="Seu e-mail"
              type="text"
              onChange={onInputChange}
            />
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="password">Senha</FormLabel>
            <Input
              id="password"
              name="password"
              placeholder="Sua senha"
              type="password"
              onChange={onInputChange}
            />
          </FormControl>
        </Stack>
        <HStack justify="space-between">
          <Checkbox defaultChecked>Lembrar</Checkbox>
          <a
            href="https://accounts.orbit.com.vc/forgotPassword?client_id=1cf18vm6q6o33gr1633an0qg2q&response_type=token&scope=email+openid+phone&redirect_uri=https%3A%2F%2Fapp.geradordevsl.com.br%2Fauth%2Flogin"
            target="_blank"
            rel="noreferrer"
          >
            Esqueceu a senha?
          </a>
        </HStack>
        <Stack spacing="4">
          <Button
            isLoading={state.isSigningIn}
            loadingText="Entrando..."
            disabled={state.isSigningIn}
            variant="primary"
            size="lg"
            onClick={onLogin}
          >
            Entrar
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default LoginPage;
