import { Outlet } from "react-router-dom";
import {
  Box,
  Container,
  Flex,
  Stack,
  Text,
  useBreakpointValue,
  useColorModeValue as mode,
} from "@chakra-ui/react";
import { Logo } from "./Logo";

const Shell = () => {
  return (
    <Box
      bg={{ base: "bg-surface", sm: "gray.800" }}
      py={{ base: "12", md: "24" }}
      minH="100vh"
    >
      <Stack spacing="6" align="center" mb="8">
        <Logo />
      </Stack>
      <Container
        maxW="md"
        py={{ base: "0", sm: "8" }}
        px={{ base: "4", sm: "10" }}
        bg={useBreakpointValue({ base: "transparent", sm: "bg-surface" })}
        boxShadow={{ base: "none", sm: "xl" }}
        borderRadius={{ base: "none", sm: "xl" }}
      >
        <Stack spacing="8">
          <Flex justifyContent={"center"}>
            <Outlet />
          </Flex>
          <Text fontSize="xs" color="subtle" textAlign="center">
            Ao continuar, você concorda com os
            <a
              href="https://www.orbitpages.com/termos-de-uso"
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              Termos de uso{" "}
            </a>
            e
            <a
              href="https://www.orbitpages.com/politica-de-privacidade"
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              Política de privacidade
            </a>
            .
          </Text>
        </Stack>
      </Container>
    </Box>
  );
};

export default Shell;
