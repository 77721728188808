import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { theme } from "@chakra-ui/pro-theme";
import { colors } from "./theme/colors";
import { fonts } from "./theme/fonts";
import "@fontsource/poppins";
import "@fontsource/biryani";
import Shell from "./layout/Shell";
import NotFoundPage from "./pages/NotFoundPage/NotFoundPage";
import LoginPage from "./pages/LoginPage/LoginPage";
import RegisterPage from "./pages/RegisterPage/RegisterPage";
import ForgotPasswordPage from "./pages/ForgotPasswordPage/ForgotPasswordPage";
import { Amplify } from 'aws-amplify';
import AmplifyConfig from "./amplify-config";

const orbitTheme = extendTheme(
  {
    fonts: fonts,
    colors: colors,
  },
  theme
);

Amplify.configure(AmplifyConfig);

export const App = () => {
  return (
    <ChakraProvider theme={orbitTheme}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Shell />}>
            <Route index element={<Navigate to="/login" />}></Route>
            <Route path="login" element={<LoginPage />}></Route>
            <Route path="register" element={<RegisterPage />}></Route>
            <Route
              path="forgot-password"
              element={<ForgotPasswordPage />}
            ></Route>
            <Route path="*" element={<NotFoundPage />}></Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </ChakraProvider>
  );
};
